import {
  Button,
  ButtonGroup,
  Textarea,
  Switch,
  FormLabel,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import useAsync from "../../hooks/useAsync.ts";
import { Helmet } from "react-helmet";
import { pinyin } from "pinyin-pro";
import cx from "classnames";
import "./index.scss";
const CharReg = /[0-9一-龟]/g;
const INPUTS = "①②③④⑤⑥⑦⑧⑨⑩⒈⒉⒊⒋⒌⒍⒎⒏⒐⒑";
const CELL_PER_LINE = 20;
const CELL_SIZE = 10;
const LEFT_PARE = ["(", "（"];
const RIGHT_PARE = [")", "）"];
const ENDINGS = ["。", ".", "，", ",", "，", "!", "！", "?", "？", "、"];
const IGNORES = [...LEFT_PARE, ...RIGHT_PARE];
const CANT_BE_FIRST = [...RIGHT_PARE, ...ENDINGS, "”", "》", ":", "："];
export default function ParaGen() {
  const [chars, setChars] = useState(
    window.localStorage.getItem("cache") ?? ""
  );
  useEffect(() => {
    window.onbeforeprint = () => {
      document.getElementById("panel").classList.add("print");
    };
    window.onafterprint = () => {
      document.getElementById("panel").classList.remove("print");
    };
    return () => {
      window.onbeforeprint = null;
      window.onafterprint = null;
    };
  }, []);
  window.localStorage.setItem("cache", chars);
  // const regulatedChars = chars.replaceAll(/[^一-龟]/g, "");

  const regulatedChars = chars.split("");
  const eles = [];
  const appendixes = [];
  for (let index = 0; index < regulatedChars.length; index++) {
    const char = regulatedChars[index];
    const prevChar = regulatedChars[index - 1];
    const nextChar = regulatedChars[index + 1];
    const isFirstOfLine = eles.length % CELL_PER_LINE === 0;
    const isLastOfLine = eles.length % CELL_PER_LINE === CELL_PER_LINE - 1;
    switch (true) {
      case char === "\n":
        if (isFirstOfLine) continue;
        // 如果是换行符，则自动填满剩余行
        const remainCellCount = CELL_PER_LINE - (eles.length % CELL_PER_LINE);
        for (let i = 0; i < remainCellCount; i++) {
          eles.push(<ParaChar char={""}></ParaChar>);
        }
        break;
      case isFirstOfLine &&
        CANT_BE_FIRST.includes(char) &&
        prevChar !== undefined:
        console.log("case 1");
        const crtLine = eles.length / CELL_PER_LINE;
        const prevLineAppendix = appendixes.find((i) => i.line === crtLine - 1);
        if (prevLineAppendix) {
          prevLineAppendix.chars.push(char);
        } else {
          appendixes.push({
            line: crtLine - 1,
            chars: [char],
          });
        }
        // 如果头部是句号逗号等，跳过
        continue;
      case isLastOfLine && RIGHT_PARE.includes(nextChar):
        console.log("case 3");
        eles.push(
          <ParaChar
            char={char}
            prevChar={prevChar}
            nextChar={nextChar}
          ></ParaChar>
        );
        index = index + 1;

        continue;

      case IGNORES.includes(char):
        console.log("ignore");
        continue;
      default:
        eles.push(
          <ParaChar
            char={char}
            prevChar={prevChar}
            nextChar={nextChar}
          ></ParaChar>
        );
        break;
    }
  }
  useEffect(() => {
    window.scrollTo(0, document.documentElement.scrollHeight);
  }, [Math.ceil(eles.length / CELL_PER_LINE)]);
  return (
    <>
      <Helmet>
        <title>段落生成</title>
      </Helmet>
      <div id="panel" className={`panel`}>
        <FormControl my={2}>
          <FormLabel htmlFor="email-alerts" mb="2">
            输入需要生成的<b>段落</b>:
          </FormLabel>
          <Textarea
            height={250}
            value={chars}
            className="input"
            placeholder="无需换行或分隔符"
            onChange={(e) => {
              setChars(e.target.value);
            }}
            name="chars"
          ></Textarea>
        </FormControl>
        {/* <FormControl
          my={2}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <FormLabel htmlFor="email-alerts" mb="0">
            笔顺
          </FormLabel>
          <Switch
            isChecked={showStep}
            onChange={(e) => {
              setShowStep(e.target.checked);
            }}
            id="showStep"
          />
        </FormControl> */}
        <FormControl my={2} pt={4} style={{ color: "gray", fontSize: "12px" }}>
          <div style={{ color: "0x000", fontSize: "14px" }}>食用方法：</div>
          <div>一个空格代表一个格子</div>
          <div>换行会自动用格子填充该行</div>
          <div>下方的序号可以快速在光标处插入想要的序号</div>
          <div>按住shift单击一个字为其注音</div>
          <div>如果这个字有多个音，点击这个字切换</div>
          <div>按住ctrl点击某个格子，可以将其中的字隐藏</div>
          <div>按住alt点击某个格子，可以将注音放入格子</div>
          <div>圆括号默认不占格子</div>
        </FormControl>
        <FormControl my={2} pt={4} display="flex" justifyContent={"flex-end"}>
          <Button
            isDisabled={regulatedChars.length === 0}
            width={"100%"}
            colorScheme="blue"
            className="submit"
            onClick={() => {
              window.print();
            }}
          >
            打印
          </Button>
        </FormControl>
        <FormControl flexWrap={"wrap"} gap={"2px"}>
          {INPUTS.split("").map((i) => (
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setChars(chars + i);
              }}
            >
              {i}
            </Button>
          ))}
        </FormControl>
      </div>
      <div id="container" className={"para-con"}>
        {eles.map((i) => i)}
        {appendixes.map((i) => (
          <LineAppendix line={i.line} chars={i.chars}></LineAppendix>
        ))}
      </div>
    </>
  );
}
const ParaChar = React.memo(({ char, prevChar, nextChar }) => {
  const pys = pinyin(char, { multiple: true, type: "array" });
  const [pyIndex, setPyIndex] = useState(0);
  const [py, setPy] = useState(false);
  const [showWord, setShowWord] = useState(true);
  const [mode, setMode] = useState("normal");
  const isCHNChar = CharReg.test(char);
  const leftP = LEFT_PARE.includes(prevChar);
  const rightP = RIGHT_PARE.includes(nextChar);
  const clickHandler = (e) => {
    console.log(e);
    switch (true) {
      case e.shiftKey:
        setPy(!py);
        break;
      case e.ctrlKey:
        setShowWord(!showWord);
        break;
      case e.altKey && mode === "normal":
        setMode("py-in-grid");
        break;
      case e.altKey && mode === "py-in-grid":
        setMode("normal");
        break;
      default:
        if (pyIndex < pys.length - 1) {
          setPyIndex(pyIndex + 1);
        } else {
          setPyIndex(0);
        }
        break;
    }
  };
  return (
    <>
      <div
        onClick={clickHandler}
        className={cx("para-cell", mode, {
          leftP,
          rightP,
        })}
      >
        {py && <div className="py">{pys?.[pyIndex]}</div>}
        <div className="char">{showWord ? char : ""}</div>
      </div>
    </>
  );
});
const LineAppendix = React.memo(({ line, chars }) => {
  return (
    <>
      <div
        className="line-appendix"
        style={{
          position: "absolute",
          left: CELL_PER_LINE * (CELL_SIZE - 0.2) + "mm",
          top: line * 16 + "mm",
        }}
      >
        {chars.map((i) => i)}
      </div>
    </>
  );
});
